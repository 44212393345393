import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj32 = () => {
  return (
    <Layout>
      <SEO title="Theory of Evolution" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-32">
          <div className="py-1-heading">
            <h1>Theory of Evolution</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Theory%20of%20Evolution"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              The process of evolution done with change in DNA
              <br />
              <br />
              Let we have a file containing many 0's and 1's bits.
              <br />
              <br />
              All we have to observe the chance to evolution,
              <br />
              where and when all the 0's will become 1<br />
              Mark it...evolution is only possible when 1 is occur.
              <br />
              <br />
              Time to evolve...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj32
